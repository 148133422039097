import firebase from "firebase/app";
import { useSelector } from "react-redux";
import { getProperty, websettingsData } from "../store/reducers/webSettings";
require("firebase/auth");
require("firebase/firestore");

const FirebaseData = () => {

    const websettingsdata = useSelector(websettingsData);


    const firebaseConfig = {
        apiKey: "AIzaSyBEWHzLMYgnZKX9OPGatTvP8rVShOuT3xM",
        authDomain: "my-quiz-time-1d4ec.firebaseapp.com",
        projectId: "my-quiz-time-1d4ec",
        storageBucket: "my-quiz-time-1d4ec.appspot.com",
        messagingSenderId: "13256132886",
        appId: "1:13256132886:web:ceda20816b619841672837",
        measurementId: "G-FDDTQZ37JH"
    };


    // eslint-disable-next-line
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    } else {
        firebase.app(); // if already initialized, use that one
    }

    const auth = firebase.auth();

    const db = firebase.firestore();

    const googleProvider = new firebase.auth.GoogleAuthProvider();

    const facebookprovider = new firebase.auth.FacebookAuthProvider();

    return ({ auth, googleProvider, facebookprovider, firebase, db });

}

export default FirebaseData;
